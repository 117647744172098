import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerRow } from '@components/styled';
import { ReactComponent as ShevronIconComponent } from '@icons/wolfkit-solid/chevron-small-down-solid.svg';
import ArticleCard from './ArticleCard';
import CollapsePanel from '../../shared/ui/layout/CollapsePanel';
import ButtonCustom from '../../shared/ui/buttons/ButtonCustom';
const ArticleCardListContainer = styled(ContainerRow)(props => ({
    flexWrap: 'wrap',
    gap: props.theme.spacing_sizes.xm,
}));
const ListContainer = styled(ContainerRow)(props => ({
    flexWrap: 'wrap',
    gap: props.theme.spacing_sizes.xm,
}));
const CollapseButton = styled(ButtonCustom)(props => ({
    border: 'none',
    lineHeight: '16px',
    padding: `${props.theme.spacing_sizes.s}px 0`,
    '&:hover': {
        border: 'none',
    },
}));
const ShevronIcon = styled(ShevronIconComponent, { shouldForwardProp: propName => propName !== 'isOpen' })(props => ({
    transform: props.isOpen ? 'rotate(180deg)' : '',
    transition: 'transform 250ms',
    width: 16,
    height: 16,
    path: {
        fill: props.theme.palette.primary.main,
    },
}));
const ArticleCardList = ({ list }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const toggleIsOpen = () => setIsOpen((prev) => !prev);
    return (_jsx(ArticleCardListContainer, { children: (list === null || list === void 0 ? void 0 : list.length) > 3 ? (_jsxs(_Fragment, { children: [_jsx(CollapsePanel, { isOpen: isOpen, maxHeight: 220, children: _jsx(ListContainer, { children: list.map((card, index) => (_createElement(ArticleCard, Object.assign({}, card, { key: `article-${index}` })))) }) }), _jsx(CollapseButton, { onClick: toggleIsOpen, endIcon: _jsx(ShevronIcon, { isOpen: isOpen }), children: isOpen ? t('support.articles.hide_articles') : t('support.articles.show_articles') })] })) : (_jsx(ListContainer, { children: list.map((card, index) => (_createElement(ArticleCard, Object.assign({}, card, { key: `article-${index}` })))) })) }));
};
export default ArticleCardList;
